.pipeline-card-wrp {
  padding: 16px;
  min-width: 320px;
  @include media-breakpoint-down(md) {
    padding: 8px;
  }
}
.pipeline-card {
  position: relative;
  min-height: 138px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 16px;
  overflow: hidden;
  .stat-field {
    min-width: 134px;
  }
}
.pipeline-progress {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 8px;
  width: 100%;
  background-color: #d9d9d9;
}
.pipeline-card-actions {
  width: 100px;
  height: 80px;
  //z-index: 10;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 8px;
}
