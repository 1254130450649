$shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
$warning-color: #ffc700;
.ext-dpod-shadow {
  box-shadow: $shadow;
}
.ext-dpod-header {
  background-color: white;
  box-shadow: shadow;

  .title-text {
    font-size: 20px;
  }

  .status-headers {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: 'Roboto';
    font-size: 16px;
    color: #323232cc;
    font-weight: 400;

    .text {
      width: 50%;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.active {
        color: #006eed;
        border-bottom: 2px solid #006eed;
      }
    }
  }
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.scan-wrapper {
  position: fixed;
  bottom: 5.5%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.scan-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006eed;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 60px;
  height: 60px;
  padding: 10px;

  .qr-icon {
    font-size: 24px;
    color: white;
  }
}

.ext-dpod-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 3;
}
.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  color: #666;
  cursor: pointer;

  &:hover {
    color: #0056d3;
  }
}

.ext-dpod-footer::before {
  content: '';
  width: 70px;
  height: 34px;
  background-color: rgba(229, 229, 229, 255);
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0) rotate(180deg);
  border-radius: 40px 40px 0 0;
  box-shadow: inset 4px 4px 8px #0000001a;
}
.d-pod-card-list-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 20px 0;
}

.d-pod-card-container {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .d-pod-card {
    .d-pod-card-id {
      color: var(--Text-Primary, #323232);
      font-family: Rubik;
      font-size: 24px;
      font-weight: 400;
    }
    .d-pod-card-title {
      color: var(--Text-Primary, #323232);
      font-size: 16px;
      font-weight: 700;
    }

    .d-pod-card-body {
      .location-container {
        font-size: 12px;
        font-weight: 400;

        .location {
          width: 200px;
          color: var(--Text-Secondary, rgba(50, 50, 50, 0.8));
          a {
            color: #0066cc;
            text-decoration: none;
          }
        }

        .directions-button {
          display: flex;
          height: 30px;
          align-items: center;
          justify-content: end;
          background-color: #eaf4ff;
          color: #0066cc;
          border-radius: 5px;
          padding: 8px;
          cursor: pointer;

          svg {
            margin-left: 5px;
          }
        }
      }

      .d-pod-card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .boxes {
          font-size: 1.2rem;

          .box-count {
            font-weight: bold;
            font-size: 1.5rem;
          }
        }

        .vendor-name {
          color: var(--Text-Secondary, rgba(50, 50, 50, 0.8));
          text-align: right;
          font-size: 12px;
        }

        .date-time {
          font-size: 14px;
        }
      }
    }
  }
  .d-pod-pipeline-progress {
    position: relative;
    bottom: 0;
    height: 8px;
    width: 100%;
    background-color: #d9d9d9;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
  }
}
.qr-scanner-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.scan-method-text {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  z-index: 10;
  display: flex;
  justify-content: center;
  gap: 0.6rem;
  margin-bottom: 1rem;
}

.d-pod-scanner-frame {
  position: absolute;
  top: 20;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 500px rgba(0, 0, 0, 0.6);
  border-radius: 30px;
  &::before {
    content: '';
    position: absolute;
    margin: 5px;
    width: 50px;
    height: 50px;
    border: 6px solid #17ff00;
    border-right: none;
    border-bottom: none;
    border-top-left-radius: 30px;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    margin: 5px;
    width: 50px;
    height: 50px;
    border: 6px solid #17ff00;
    border-top-right-radius: 30px;
    border-left: none;
    border-bottom: none;
    top: 0;
    right: 0;
  }

  > div::before {
    content: '';
    position: absolute;
    margin: 5px;
    width: 50px;
    height: 50px;
    border: 6px solid #17ff00;
    border-bottom-left-radius: 30px;
    border-right: none;
    border-top: none;
    bottom: 0;
    left: 0;
  }

  > div::after {
    content: '';
    position: absolute;
    margin: 5px;
    width: 50px;
    height: 50px;
    border: 6px solid #17ff00;
    border-bottom-right-radius: 30px;
    border-left: none;
    border-top: none;
    bottom: 0;
    right: 0;
  }

  &.reject-mode {
    &::before {
      content: '';
      position: absolute;
      margin: 5px;
      width: 50px;
      height: 50px;
      border: 6px solid #c00000;
      border-right: none;
      border-bottom: none;
      border-top-left-radius: 30px;
      top: 0;
      left: 0;
    }

    &::after {
      content: '';
      position: absolute;
      margin: 5px;
      width: 50px;
      height: 50px;
      border: 6px solid #c00000;
      border-top-right-radius: 30px;
      border-left: none;
      border-bottom: none;
      top: 0;
      right: 0;
    }

    > div::before {
      content: '';
      position: absolute;
      margin: 5px;
      width: 50px;
      height: 50px;
      border: 6px solid #c00000;
      border-bottom-left-radius: 30px;
      border-right: none;
      border-top: none;
      bottom: 0;
      left: 0;
    }

    > div::after {
      content: '';
      position: absolute;
      margin: 5px;
      width: 50px;
      height: 50px;
      border: 6px solid #c00000;
      border-bottom-right-radius: 30px;
      border-left: none;
      border-top: none;
      bottom: 0;
      right: 0;
    }
  }
}

.custom-toast-content {
  display: flex;
  align-items: center;
  gap: 10px;
  .toast-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .message {
    font-size: 16px;
    line-height: 1.5;
  }
  .message-container {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 1.5rem;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  .undo-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .undo-button:hover {
    opacity: 0.8;
  }
}
.custom-toast-body-dpod {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swipe-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}

.swipe-track {
  position: relative;
  width: 100%;
  height: 52px;
  border: 2px solid #006eed;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.completed {
  border-color: #008a50;
  background: #008a50;
  cursor: default;
}
.swipe-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.swipe-handle {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 44px;
  background-color: #006eed;
  border-radius: 8px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  color: #fff;
  z-index: 2;
  transition: width ease-out;
}

.check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  z-index: 3;
}

.swipe-text {
  position: relative;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #006eed;
  z-index: 1;
  pointer-events: none;
}

.swipe-track.completed .swipe-text {
  color: #fff;
  border-color: #008a50;
}
.detail-container {
  background: #fafafa;
  border: 1px solid #0066cc;
  border-radius: 16px;
  .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, sans-serif;
    margin-bottom: 20px;
  }

  .status span {
    margin-right: 5px;
  }

  .bottom-dashed-line {
    border-bottom: 2px dashed #000;
  }
}
.bottom-modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 16px;
}
.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.8);
  z-index: 999;
}
.dpod-warning-btn {
  border: 1px solid $warning-color;
  border-radius: 8px;
  width: 100%;
  padding: '10px';
  cursor: 'pointer';
  color: black;
  background-color: $warning-color;
}

.swiper-body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.swiper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.swiper-slide img {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.swiper-pagination {
  position: relative;
  bottom: -20px;
  margin-top: 20px;
  text-align: center;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #000;
  opacity: 0.6;
}

.swiper-pagination-bullet-active {
  background-color: #006eed;
  opacity: 1;
}
.inputBorder-none {
  border-bottom: 2px solid #027aff;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}
.otp-input {
  border: none;
  outline: none;
  width: 44px;
  border-bottom: 1px solid #006eed;
  background-color: #fafafa;
}
.otp-input::placeholder {
  color: #32323266;
}
.borders {
  border: 1px solid #dededecc;
  background-color: #fafafa;
  width: 320px;
}

.profile-frame {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
}

.edit-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: center;
  font-size: 14px;
  border-bottom-left-radius: 70%;
  border-bottom-right-radius: 70%;
  width: 100%;
  height: 24px;
}

.status-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.progress-container {
  width: 100%;
  padding-bottom: 2px;
}
.status-container {
  padding-bottom: 1.5rem;
}
.res-dpod-card {
  padding: 16px;
}
.last-scan-card {
  height: 130px;
}
@media (max-height: 700px) {
  .status-section,
  .progress-container,
  .status-container {
    padding-bottom: 4px;
  }
  .res-dpod-card {
    padding: 5px;
  }
  .last-scan-card {
    height: 110px;
  }
}

.custom-dpod-toast .Toastify__toast {
  border-radius: 28px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px !important;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 12.59px;
  font-family: 'Rubik';
  font-weight: 400;
  opacity: 0.85;
  box-sizing: border-box;
  border: none;
  min-height: 24px;
}

.Toastify__toast.success {
  background: rgba(0, 138, 80, 1) !important;
  border: none;
}

.Toastify__toast.reject {
  background: rgba(192, 0, 0, 1) !important;
  border: none;
}
